

.margin-top-small {
    margin-top: 1vh !important; 
}

.margin-top-3 {
    margin-top: 3px;
}

.margin-top-6 {
    margin-top: 6px;
}

.margin-top-basic {
    margin-top: 2vh !important;
}

.margin-top-medium {
    margin-top: 3vh !important;
}

.margin-bottom-small {
    margin-bottom: 1vh;
}

.margin-bottom-basic {
    margin-bottom: 2vh !important;
}

.margin-bottom-medium {
    margin-bottom: 3vh !important;
}

.padding-bottom-basic {
    padding-bottom: 2vh;
}

.padding-right-small {
    padding-right: 1vh;
}

.margin-left-basic {
    margin-left: 2vw !important;
}

.margin-left-6 {
    margin-left: 6px;
}

.margin-left-3 {
    margin-left: 3px;
}

.margin-right-3 {
    margin-right: 3px;
}

.margin-right-6 {
    margin-right: 6px;
}

.margin-right-small {
    margin-right: 1vw;
}

.margin-left-small {
    margin-left: 1vw;
}

.margin-right-basic {
    margin-right: 2vw;
}

.margin-bottom-6 {
    margin-bottom: 6px;
}

.margin-bottom-2 {
    margin-bottom: 2px;
}

.margin-left-12 {
    margin-left: 12px;
}