@import './colors.scss';
@import './variables.scss';

.text-small-title {
    @include font-size-map($body-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black;
    font-weight: normal;
}


.text-title {
    @include font-size-map($title-font-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.text-promotion {
    @include font-size-map($promotion-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.text-body {
    @include font-size-map($secondary-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.text-small {
    @include font-size-map($small-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.text-tiny {
    @include font-size-map($tiny-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.3px;
}

.textcenter {
    text-align: center;
}

.bold {
    font-weight: 600 !important;
}

.clickable {
    cursor: pointer;
}

.underline {
    text-decoration-line: underline !important; 
}

.space-between {
    justify-content: space-between;
}

.account-table-title {
    display: inline-flex;
    align-items: center;
}

.space-evenly { 
    justify-content: space-evenly;
}

hr {
    margin-top: 2vh !important; 
    margin-bottom: 2vh !important;
}


.large-icon {
    height: 50px !important;
    width: 50px !important; 
}

.medium-icon {
    height: 28px !important;
    width: 28px !important;
}

.x-medium-icon {
    height: 25px;
    width: 25px;
}

.small-icon {
    height: 20px;
    width: 20px;
}

.tiny-icon {
    width: 18px !important;
    height: 18px !important;
}

.full-width {
    width: 100%;
}

.full-height { 
    height: 100%;
}

.italic {
    font-style: italic;
}

.capitalize {
    text-transform: capitalize;
}

.light { 
    font-weight: 200;
}

.bolder { 
    font-weight: 800 !important;
}

.fit-content {
    width: fit-content
}

.MuiTabs-root {
    overflow: unset !important;
}

.MuiTab-root > .MuiTab-wrapper{
    text-align: left !important;
    align-items: flex-start !important;
}

.MuiGridList-root {
    overflow-y: hidden !important;
}

.text-overflow-elipsis {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-overflow-elipsis-small {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ai-list {
    height: 400px;
    overflow-y: auto;
}

.small-margin-right {
    margin-right: 15px;
}

.x-large-icon {
    height: 80px !important;
    width: 80px !important;
}